import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Header = () => {
    const [notificationPermission, setNotificationPermission] = useState(
        typeof Notification !== "undefined" ? Notification.permission : "denied"
    );
    const nav = useNavigate()
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null)
    const buttonRef = useRef(null)
    const [notifications, setNotifications] = useState([])


    useEffect(() => {
        if (notificationPermission === 'default') {
            Notification.requestPermission().then((permission) => {
                setNotificationPermission(permission);
            });
        }
    }, [notificationPermission])


    const showNotification = useCallback(
        (notifications) => {
            const lang = localStorage.lang || 'ar';
            if (notificationPermission === 'granted') {
                if (window.PushManager && notifications.length > 0) {
                    notifications.forEach((notification) => {
                        navigator.serviceWorker.ready
                            .then((registration) => {
                                registration.showNotification(notification.title, {
                                    body: notification.description,
                                    icon: 'logo.png',
                                });
                            })
                            .catch((error) => {
                                console.error('Error showing notification:', error);
                            });
                    });
                    localStorage.setItem('lastUpdatedNotification', Date.now())
                }
            }
        },
        [notificationPermission]
    )

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSignOut = () => {
        setIsModalOpen(false)
        localStorage.clear()
        nav('/')
    };

    const getData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/notification/newNotifications/${user._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            const lastUpdatedNotification = localStorage.getItem('lastUpdatedNotification');

            const lastUpdatedTimestamp = lastUpdatedNotification ? parseInt(lastUpdatedNotification) : 0;

            const newNotifications = res.data.filter(elt => {
                const notificationTimestamp = new Date(elt.createdAt).getTime()
                return notificationTimestamp > lastUpdatedTimestamp;
            })

            setNotifications(res.data)
            if (newNotifications.length > 0)
                showNotification(newNotifications)
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }


    const scheduleDailyNotification = () => {
        if (Notification.permission !== "granted") return;
    
        const now = new Date();
        const targetTime = new Date();
        targetTime.setHours(9, 0, 0, 0); // 9 AM today
    
        let delay = targetTime.getTime() - now.getTime();
        if (delay < 0) {
            delay += 24 * 60 * 60 * 1000; // Schedule for tomorrow if 9 AM has passed
        }
    
        setTimeout(() => {
            showMorningNotification();
            setInterval(showMorningNotification, 24 * 60 * 60 * 1000); // Repeat every 24 hours
        }, delay);
    };
    
    const showMorningNotification = () => {
        new Notification("Good Morning!", {
            body: "Start your day with a fresh mind! 🌞",
            icon: "/logo.png",
        });
    };
    

    useEffect(() => {
        if (user)
            getData()
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsModalOpen(false);  // Close modal if the click is outside
            }
        };

        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (notificationPermission === "default") {
            Notification.requestPermission().then((permission) => {
                setNotificationPermission(permission);
                if (permission === "granted") {
                    scheduleDailyNotification(); 
                }
            });
        } else if (notificationPermission === "granted") {
            scheduleDailyNotification();
        }
    }, [notificationPermission]);
    

    return (
        <div className="w-[88%] m-auto mt-[69px]">
            <div className="w-full flex justify-between items-center">
                <Link to={'/notifications'}>
                    <button type='button' className='text-white relative' ><i class="fa-solid fa-bell"></i>
                        {notifications.length > 0 && <span className="flex h-4 w-4 absolute -top-1 -right-3">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#7919ff] opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-4 w-4 bg-[#7919ff] text-[10px] justify-center items-center"> {notifications.length}</span>
                        </span>}
                    </button>
                </Link>
                <button type='button' className='text-white hidden '><i className="fa-solid fa-bars"></i></button>
                <img src="./assets/tryfit-logo.png" alt="TryFit logo" className="w-[96px] " />
                <button type='button' className='text-white ' onClick={handleModalToggle} ref={buttonRef}><i className="fa-solid fa-ellipsis-vertical"></i></button>
            </div>
            {isModalOpen && (
                <div className="absolute mt-2 right-2 bg-white p-4 rounded-lg w-40 shadow-lg z-50 space-y-2" ref={modalRef}>
                    <Link
                        to={`/weeklyReport`}
                        className=" block w-full text-center font-bold"
                    >
                        Weekly report
                    </Link>
                    <Link
                        to={`/monthlyReport`}
                        className=" block w-full text-center font-bold "
                    >
                        Monthly report
                    </Link>
                    <button
                        onClick={handleSignOut}
                        className=" text-red-500 w-full text-center "
                    >
                        Sign Out
                    </button>
                </div>)
            }
        </div>
    )
}

export default Header