import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import { Link } from 'react-router-dom'
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from './Loading'
import Header from '../components/Header'


const Notification = () => {
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const nav = useNavigate()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [lastSeen, setLastSeen] = useState(null)

    const seen = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/notification/lastSeen/${user._id}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            console.log(res)
        } catch (err) {
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }
    const getData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/notification/${user._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setNotifications(res.data)
            const resUser = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${user._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setLastSeen(resUser.data.lastSeenNotifications)
            setDataLoaded(true)
            seen()
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }



    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()
            if (isAuth) {
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()
    }, [])




    return (
        dataLoaded ? <div className="relative max-w-[500px] min-h-[100vh]  mx-auto  overflow-hidden pb-[110px]   " style={{
        }}>




            {/* <DownloadBtn/> */}

            <ToastContainer />
            <div className='absolute top-0 -z-10  w-full h-[50%] bg-white opacity-90'>
                <img src="./assets/victor-freitas-hOuJYX2K5DA-unsplash.jpg" alt="background" className=" w-full object-cover opacity-100 blur-lg -z-10 " />
            </div>
            <Header />
            <div className='w-full rounded-t-[30px] min-h-[100vh] mt-[82px]' style={{ background: `linear-gradient(180deg, rgba(83,49,146,1) 0%, rgba(59,35,104,1) 100%)` }}>
                <div className='w-[90%] py-[53px] m-auto ' >
                    <h1 className='text-white text-xl font-semibold mb-4 '>Notifications</h1>
                    {notifications.map((elt, index) => <div key={index} className={`${!(lastSeen && lastSeen > elt.createdAt) ? 'bg-gray-300' : 'bg-white'} py-4 px-3 rounded-md mb-2`}>
                        <h1 className=' text-md font-medium '>{elt.title}</h1>
                        <p className='text-xs'>{elt.description}</p>
                    </div>)}
                </div>
            </div>
            <NavBar />
        </div>
            : <Loading />

    )
}

export default Notification