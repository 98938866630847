import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route, HashRouter, Outlet, useLocation } from "react-router-dom";
import NoSleep from 'nosleep.js'; // Import NoSleep.js
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Welcome from './pages/Welcome';
import Home from './pages/Home';
import Loading from './pages/Loading';
import Description from './pages/Description';
import Quetions from './pages/Quetions';
import Program from './pages/Program';
import Calender from './pages/Calender';
import Exircices from './pages/Exircices';
import Exercice from './pages/Exercice';
import useAddToHomescreenPrompt from './hooks/useAddToHomescreenPrompt';
import Profile from './pages/Profile';
import WeeklyReport from './pages/WeeklyReport';
import Notification from './pages/Notification';
import MonthlyReport from './pages/MonthlyReport';

// Register service worker if available
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/sw.js')
    .then((reg) => { })
    .catch((err) => console.log('Service worker registration failed:', err));
}

const Layout = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [showInstallButton, setShowInstallButton] = useState(true);
  const location = useLocation();
  const [noSleep, setNoSleep] = useState(null);
  let wakeLock = null;

  // Request screen wake lock if supported
  const requestWakeLock = async () => {
    if ('wakeLock' in navigator) {
      // Use the native Wake Lock API if available
      try {
        wakeLock = await navigator.wakeLock.request('screen');
      } catch (err) {
        console.error('Wake Lock failed:', err);
      }
    } else {
      // Fallback to NoSleep.js for iOS or unsupported browsers
      const noSleepInstance = new NoSleep();
      noSleepInstance.enable();
      setNoSleep(noSleepInstance);
    }
  };

  // Release screen wake lock
  const releaseWakeLock = () => {
    if (wakeLock) {
      wakeLock.release();
      wakeLock = null;
    }
    if (noSleep) {
      noSleep.disable(); // Disable NoSleep.js
      setNoSleep(null);
    }
  };

  // Manage screen wake lock based on visibility and navigation
  useEffect(() => {
    setShowInstallButton(true);
    requestWakeLock();

    // Reapply wake lock if the page visibility changes
    document.addEventListener('visibilitychange', () => {
      if (wakeLock !== null && document.visibilityState === 'visible') {
        requestWakeLock();
      }
    });

    // Clean up on component unmount
    return () => {
      releaseWakeLock();
    };
  }, [location.pathname]);

  // Close the install prompt
  const handleCloseClick = () => {
    setShowInstallButton(false);
  };

  return (
    <div className="app-layout">
      <div className="absolute right-3 top-2 z-[1000]">
        {(prompt && showInstallButton) && (
          <div className="relative w-[90vw] mx-auto">
            <button
              className="heartbeat-background-button block text-center py-3 shadow-2xl text-white bg-[#000000] text-base rounded-[10px] w-full"
              onClick={promptToInstall}
            >
              Install TryFit app
            </button>
            <button
              className="absolute -top-1 -right-2 text-black bg-white w-7 h-7 rounded-full text-xl"
              onClick={handleCloseClick}
            >
              ✕
            </button>
          </div>
        )}
      </div>

      <Outlet />
    </div>
  );
};

// Root rendering
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HashRouter basename='/'>
      <Routes>
        {/* Default layout for all routes */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/success-login" element={<Description />} />
          <Route path="/questions" element={<Quetions />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/program" element={<Program />} />
          <Route path="/calender/:programId" element={<Calender />} />
          <Route path="/exercices/:programId/:section/:exercicesList" element={<Exircices />} />
          <Route path="/exercice/:programId/:section/:day/:exerciceId" element={<Exercice />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/weeklyReport" element={<WeeklyReport />} />
          <Route path="/monthlyReport" element={<MonthlyReport />} />
          <Route path="/notifications" element={<Notification />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
