import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import { Link } from 'react-router-dom'
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from './Loading'
import Header from '../components/Header'


const MonthlyReport = () => {
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const nav = useNavigate()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [statistics, setStatistics] = useState([])
    const [selectedProgram, setSelectedProgram] = useState("")
    const [selectedSection, setSelectedSection] = useState("")
    const [programs, setPrograms] = useState([])
    const [sections, setSections] = useState([])

    const selectProgram = async (programId) => {
        setSelectedProgram(programId)
        const sections = programs.find(elt => elt.id == programId).calender
        setSections(sections)
    }

    const getStatistics = async (sectionId) => {
        if (!sectionId)
            return
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/statistics/userWeeklyStatistics/${user._id}/${selectedProgram}/${sectionId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setStatistics(res.data)
            console.log(res.data)
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }

    const getData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/program/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setPrograms(res.data)
            setDataLoaded(true)
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }



    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()
            if (isAuth) {
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()
    }, [])




    return (
        dataLoaded ? <div className="relative max-w-[500px] min-h-[100vh]  mx-auto  overflow-hidden pb-[110px]   " style={{
        }}>




            {/* <DownloadBtn/> */}

            <ToastContainer />
            <div className='absolute top-0 -z-10  w-full h-[50%] bg-white opacity-90'>
                <img src="./assets/victor-freitas-hOuJYX2K5DA-unsplash.jpg" alt="background" className=" w-full object-cover opacity-100 blur-lg -z-10 " />
            </div>
            <Header />
            <div className='w-full rounded-t-[30px] min-h-[100vh] mt-[82px]' style={{ background: `linear-gradient(180deg, rgba(83,49,146,1) 0%, rgba(59,35,104,1) 100%)` }}>
                <div className='w-[90%] pt-[53px] m-auto space-y-[35px]' >
                    <h1 className='text-white text-xl font-semibold '>Monthly Progress Report</h1>
                    <select className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={selectedProgram} onChange={(e) => selectProgram(e.target.value)}>
                        <option disabled value={''}>Select Program</option>
                        {programs.map((elt, index) => <option key={index} value={elt.id}>{elt.title}</option>)}
                    </select>

                    <select className="bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={selectedSection} onChange={(e) => {
                        setSelectedSection(e.target.value);
                        getStatistics(e.target.value);
                    }}>
                        <option disabled value={''}>Select Week</option>
                        {sections.map((elt, index) => <option key={index} value={index}>{elt.title}</option>)}
                    </select>


                    {/* {statistics.map((elt, index) =>
                        <div className='bg-white py-4 px-3 rounded-md' key={index}>
                            {elt.sections.map((elt1, i) => <>
                                <h1 className=' text-lg font-medium  mb-5'>{elt.title} - {elt1.sectionTitle} </h1>
                                {elt1.exercises.map((elt2, index2) => <div className='flex gap-1 items-center mb-3'>
                                    <p className='text-xs text-gray-500 text-nowrap'>{elt2.day}</p>
                                    <div className='h-2 bg-green-500 w-full'></div>
                                    <p className='text-xs text-gray-500'>{elt2.exercicesPercent}%</p>
                                </div>)}
                            </>
                            )}
                        </div>
                    )} */}
                    <div className='bg-white py-4 px-3 rounded-md'>
                        {statistics && sections.find((elt , index) => index == selectedSection) && sections.find((elt , index) => index == selectedSection).exercices.map((elt, index) => {
                            console.log(selectedSection)
                            const dayStatistics = statistics.find(elt1 => elt1.day == index);
                            const percentage = dayStatistics ? (dayStatistics.exercices.length * 100) / elt.length : 0;

                            
                            return (
                                <div key={index} className='flex gap-1 items-center mb-3'>
                                    <p className='text-xs text-gray-500 text-nowrap'>day {index + 1}</p>

                                    <div className='relative h-2 bg-gray-300 w-full'>
                                        {/* Dynamically setting width using the calculated percentage */}
                                        <div
                                            className='bg-green-500 absolute h-full z-10'
                                            style={{ width: `${percentage}%` }} // Set width dynamically using style
                                        ></div>
                                    </div>
                                    <p className='text-xs text-gray-500 text-nowrap w-9 text-right'>{percentage.toFixed(2)} %</p>
                                </div>
                            );
                        })}
                    </div>

                </div>
            </div>
            <NavBar />
        </div>
            : <Loading />

    )
}

export default MonthlyReport